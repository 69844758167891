import { Text, TopLevelBlock } from '@contentful/rich-text-types';

// average reading speed in terms of words read per minute
const AVERAGE_READING_SPEED_PER_MINUTE = 250;

export const getReadTime = ({
  body,
  title,
  preamble,
}: {
  title: string | undefined;
  preamble: string | undefined;
  body: TopLevelBlock[] | undefined;
}) => {
  let bodyLength = 0;
  body?.forEach((element) => {
    element.content.forEach((text) => {
      if ((text as Text).value) {
        bodyLength += (text as Text).value.split(' ').length;
      }
    });
  });

  const titleLength = title?.split(' ').length || 0;
  const preambleLength = preamble?.split(' ').length || 0;
  const documentLength = titleLength + preambleLength + bodyLength;
  return Math.round(documentLength / AVERAGE_READING_SPEED_PER_MINUTE);
};
